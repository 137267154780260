<script setup>
const { slug } = useRoute().params
const config = useRuntimeConfig();

const story = await useAsyncStoryblok(
    slug && slug.length > 0 ? slug.join('/') : 'home',
    {
        version: config.STORYBLOK_STAGE,
        resolve_links: 'link'
    }
)

</script>

<template>
    <StoryblokComponent v-if="story" :blok="story.content" />
</template>